import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faCar } from '@fortawesome/free-solid-svg-icons';

import styles from './PageLoader.module.sass';

const pageLoader = (props) => (
  <div className={styles.Centered}>
    <span>
      <FontAwesomeIcon className={styles.Circle} icon={faCircleNotch} spin />
      <FontAwesomeIcon className={styles.Car} icon={faCar} />
    </span>
    <div className={styles.Text}>{props.text}</div>
  </div>
)

export default pageLoader;
