import { React, Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faUser, faStoreAlt, faChartLine, faEuroSign, faList, faGavel } from '@fortawesome/free-solid-svg-icons';

import logo from '../../../assets/logo_light.png'

import styles from './Sidebar.module.sass';

class Sidebar extends Component{
  render(){
    const menu_items = [];

    if(this.props.role === 'user'){
      menu_items.push({name: 'Mis coches', link: '/coches', icon: faCar});
      menu_items.push({name: 'Mis datos', link: '/perfil', icon: faUser});
    }
    if(this.props.role === 'concessionaire'){
      menu_items.push({name: 'Subastas activas', link: '/coches-cerca', icon: faGavel});
      if(this.props.concessionaire_id === '61712a35d508666033b0e9ed'){
        menu_items.push({name: 'Mis Coches', link: '/coches-concesionario', icon: faCar});
      }
      menu_items.push({name: 'Concesionario', link: '/mi-concesionario', icon: faStoreAlt});
      menu_items.push({name: 'Mis datos', link: '/perfil', icon: faUser});
      menu_items.push({name: 'Tarifas', link: '/tarifas', icon: faEuroSign});
    }
    if(this.props.role === 'admin'){
      menu_items.push({name: 'Dashboard', link: '/dashboard', icon: faChartLine});
      menu_items.push({name: 'Coches', link: '/cochesadmin', icon: faCar});
      menu_items.push({name: 'Concesionarios', link: '/concesionarios', icon: faStoreAlt});
      menu_items.push({name: 'Glosario', link: '/glosarioadmin', icon: faList});
      // menu_items.push({name: 'Páginas transaccionales', link: '/paginastransaccionalesadmin', icon: faCopy});
    }

    return(
      <div className={styles.Sidebar}>
        <div className={styles.Logo}>
          <img src={logo} alt='logo' />
        </div>
        <div className={styles.Menu}>
          {
            menu_items.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className={this.props.pathname.indexOf(item.link) > -1 ? styles.Active : null}
              >
                <FontAwesomeIcon icon={item.icon} />
                <div className={styles.Text}>{item.name}</div>
              </Link>
            ))
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    concessionaire_id: state.auth.concessionaire_id
  }
}

export default connect(mapStateToProps)(Sidebar);
