import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

import styles from './Header.module.sass';

const header = (props) => (
  <div className={styles.Header}>
    {
      props.user_name ?
        <div>Bienvenido, {props.user_name}</div>
        :
        null
    }
    <a href='/logout'>
      <FontAwesomeIcon icon={faPowerOff} />
    </a>
  </div>
);

export default header;
