import axios from '../../shared/axios-checkauto';

import * as actionTypes from './actionTypes';

const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authReset = () => {
  return {
    type: actionTypes.AUTH_RESET
  }
}

const authSuccess = (token, name, role, concessionaire_id) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    name: name,
    role: role,
    concessionaire_id: concessionaire_id
  }
}

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  }
}

export const logOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('name');
  localStorage.removeItem('role');
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logOut());
    }, expirationTime * 1000);
  }
}

export const auth = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password
    }
    axios.post('/users/signIn', authData)
      .then(res => {
        const expirationDate = new Date(new Date().getTime() + res.data.expiresIn * 1000);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('name', res.data.name);
        localStorage.setItem('role', res.data.role);
        localStorage.setItem('concessionaire_id', res.data.concessionaire_id);
        localStorage.setItem('expirationDate', expirationDate);
        dispatch(authSuccess(res.data.token, res.data.name, res.data.role, res.data.concessionaire_id));
        dispatch(checkAuthTimeout(res.data.expiresIn));
      })
      .catch(err => {
        console.log(err);
        let errorMessage = 'Unidentified error';
        if(err.response){
          errorMessage = err.response.data.error;
        }
        dispatch(authFail(errorMessage));
      });
  }
}

export const nameChange = (name) => {
  localStorage.setItem('name', name);
  return {
    type: actionTypes.NAME_CHANGE,
    name: name
  }
}

const autoAuthDone = () => {
  return {
    type: actionTypes.AUTO_AUTH_DONE
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if(!token){
      dispatch(logOut());
      dispatch(autoAuthDone());
    }else{
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if(expirationDate > new Date()){
        const name = localStorage.getItem('name');
        const role = localStorage.getItem('role');
        const concessionaire_id = localStorage.getItem('concessionaire_id');
        dispatch(authSuccess(token, name, role, concessionaire_id));
        dispatch(autoAuthDone());
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000));
      }else{
        dispatch(logOut());
        dispatch(autoAuthDone());
      }
    }
  }
}
