import React, { Suspense, Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import {Helmet} from 'react-helmet';

import * as actions from './store/actions';

import PageLoader from './components/UI/PageLoader/PageLoader';
import Layout from './hoc/Layout/Layout';
/*import Home from './containers/Home/Home';*/

// const PageLoader = React.lazy(() => import('./components/UI/PageLoader/PageLoader'));
// const Layout = React.lazy(() => import('./hoc/Layout/Layout'));
const Home = React.lazy(() => import('./containers/Home/Home'));
const LazyConcessionaireLanding = React.lazy(() => import('./containers/Landings/ConcessionaireLanding/ConcessionaireLanding'));
const LazyUserLanding = React.lazy(() => import('./containers/Landings/UserLanding/UserLanding'));
const LazyAppraiseLanding = React.lazy(() => import('./containers/Landings/AppraiseLanding/AppraiseLanding'));
const LazyPrivacyPolicy = React.lazy(() => import('./containers/Landings/PrivacyPolicy/PrivacyPolicy'));
const LazyCookiesPolicy = React.lazy(() => import('./containers/Landings/CookiesPolicy/CookiesPolicy'));
const LazyLegalAdvice = React.lazy(() => import('./containers/Landings/LegalAdvice/LegalAdvice'));
const LazyDashboard = React.lazy(() => import('./containers/Dashboard/Dashboard'));
const LazyUserCars = React.lazy(() => import('./containers/User/Cars/Cars'));
const LazyConcessionaireCars = React.lazy(() => import('./containers/Concessionaire/Cars/Cars'));
const LazyConcessionaireEditCar = React.lazy(() => import('./containers/Concessionaire/Cars/Edit'));
const LazyAdminCars = React.lazy(() => import('./containers/Admin/Cars/Cars'));
const LazyAdminConcessionaireList = React.lazy(() => import('./containers/Admin/Concessionaires/List'));
const LazyAdminConcessionaireAdd = React.lazy(() => import('./containers/Admin/Concessionaires/Add'));
const LazyAdminGlossaryList = React.lazy(() => import('./containers/Admin/Glossary/List'));
const LazyAdminGlossaryAdd = React.lazy(() => import('./containers/Admin/Glossary/Add'));
const LazyAdminGlossaryEdit = React.lazy(() => import('./containers/Admin/Glossary/Edit'));
/*const LazyAdminTransactionalPageList = React.lazy(() => import('./containers/Admin/TransactionalPage/List'));
const LazyAdminTransactionalPageAdd = React.lazy(() => import('./containers/Admin/TransactionalPage/Add'));
const LazyAdminTransactionalPageEdit = React.lazy(() => import('./containers/Admin/TransactionalPage/Edit'));
const LazyTransactionalPages = React.lazy(() => import('./containers/TransactionalPages/TransactionalPages'));*/
const LazyGlossary = React.lazy(() => import('./containers/Glossary/Glossary'));
const LazyProfile = React.lazy(() => import('./containers/Profile/Profile'));
const LazyEditCar = React.lazy(() => import('./containers/Car/EditCar/EditCar'));
const LazyShowCar = React.lazy(() => import('./containers/Car/ShowCar/ShowCar'));
const LazyAuctionCars = React.lazy(() => import('./containers/Concessionaire/AuctionCars/AuctionCars'));
const LazyEditConcessionaire = React.lazy(() => import('./containers/Concessionaire/EditConcessionaire/EditConcessionaire'));
const LazyRates = React.lazy(() => import('./containers/Concessionaire/Rates/Rates'));
const LazyLogOut = React.lazy(() => import('./containers/Auth/Logout/Logout'));
const LazySignIn = React.lazy(() => import('./containers/Auth/SignIn/SignIn'));
const LazyPasswordReset = React.lazy(() => import('./containers/Auth/PasswordReset/PasswordReset'));
const LazyPasswordRecovery = React.lazy(() => import('./containers/Auth/PasswordRecovery/PasswordRecovery'));
const LazyEmailUnsubscribe = React.lazy(() => import('./containers/User/EmailUnsubscribe/EmailUnsubscribe'));

const PrivateRoute = ({ children, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ?
          children
          :
          <Redirect to="/signin" />
      }
    />
  );
}

if(process.env.NODE_ENV !== 'development'){
  const gtmParams = {
      gtmId: 'GTM-WJJH3L5'
  }

  TagManager.initialize(gtmParams);
}

class App extends Component{
  componentDidMount(){
    this.props.onTryAutoSignin();
  }

  render(){
    let layout = null;
    if(this.props.autoAuthDone){
      layout = (
        <Layout location={this.props.location}>
          <Helmet>
            <title>Checkauto.es</title>
            <meta
              name="description"
              content="Checkauto"
            />
            <link rel="canonical" href="https://checkauto.es" />
          </Helmet>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <PrivateRoute path='/logout' isAuth={this.props.isAuth}>
                <LazyLogOut />
              </PrivateRoute>
              <PrivateRoute path='/dashboard' isAuth={this.props.isAuth}>
                <LazyDashboard />
              </PrivateRoute>
              <PrivateRoute path='/coches/nuevo' isAuth={this.props.isAuth}>
                <LazyEditCar />
              </PrivateRoute>
              <PrivateRoute path='/coches/editar/:id' isAuth={this.props.isAuth}>
                <LazyEditCar />
              </PrivateRoute>
              <PrivateRoute path='/coches/:id' isAuth={this.props.isAuth}>
                <LazyShowCar />
              </PrivateRoute>
              <PrivateRoute path='/coches' isAuth={this.props.isAuth}>
                <LazyUserCars />
              </PrivateRoute>
              <PrivateRoute path='/coches-concesionario/nuevo' isAuth={this.props.isAuth}>
                <LazyConcessionaireEditCar />
              </PrivateRoute>
              <PrivateRoute path='/coches-concesionario/editar/:id' isAuth={this.props.isAuth} key='CarEdit'>
                <LazyConcessionaireEditCar />
              </PrivateRoute>
              <PrivateRoute path='/coches-concesionario' isAuth={this.props.isAuth}>
                <LazyConcessionaireCars />
              </PrivateRoute>
              <PrivateRoute path='/cochesadmin' isAuth={this.props.isAuth}>
                <LazyAdminCars />
              </PrivateRoute>
              <PrivateRoute path='/glosarioadmin/agregar' isAuth={this.props.isAuth}>
                <LazyAdminGlossaryAdd />
              </PrivateRoute>
              <PrivateRoute path='/glosarioadmin/editar/:id' isAuth={this.props.isAuth}>
                <LazyAdminGlossaryEdit />
              </PrivateRoute>
              <PrivateRoute path='/glosarioadmin' isAuth={this.props.isAuth}>
                <LazyAdminGlossaryList />
              </PrivateRoute>
              {/*<PrivateRoute path='/paginastransaccionalesadmin/agregar' isAuth={this.props.isAuth}>
                <LazyAdminTransactionalPageAdd />
              </PrivateRoute>
              <PrivateRoute path='/paginastransaccionalesadmin/editar/:id' isAuth={this.props.isAuth}>
                <LazyAdminTransactionalPageEdit />
              </PrivateRoute>
              <PrivateRoute path='/paginastransaccionalesadmin' isAuth={this.props.isAuth}>
                <LazyAdminTransactionalPageList />
              </PrivateRoute>*/}
              <PrivateRoute path='/concesionarios/agregar' isAuth={this.props.isAuth}>
                <LazyAdminConcessionaireAdd />
              </PrivateRoute>
              <PrivateRoute path='/concesionarios/editar/:id' isAuth={this.props.isAuth}>
                <LazyEditConcessionaire />
              </PrivateRoute>
              <PrivateRoute path='/concesionarios' isAuth={this.props.isAuth}>
                <LazyAdminConcessionaireList />
              </PrivateRoute>
              <PrivateRoute path='/perfil' isAuth={this.props.isAuth}>
                <LazyProfile />
              </PrivateRoute>
              <PrivateRoute path='/coches-cerca' isAuth={this.props.isAuth}>
                <LazyAuctionCars />
              </PrivateRoute>
              <PrivateRoute path='/mi-concesionario' isAuth={this.props.isAuth}>
                <LazyEditConcessionaire />
              </PrivateRoute>
              <PrivateRoute path='/tarifas' isAuth={this.props.isAuth}>
                <LazyRates />
              </PrivateRoute>
              <Route path='/glosario/:slug' component={LazyGlossary} />
              <Route path='/glosario' component={LazyGlossary} />
              <Route path='/passwordRecovery' component={LazyPasswordRecovery} />
              <Route path='/passwordReset/:hash' component={LazyPasswordReset} />
              <Route path='/passwordSet/:hash' component={LazyPasswordReset} />
              <Route path='/cancel_subscription/:mail' component={LazyEmailUnsubscribe} />
              <Route path='/profesionales' component={LazyConcessionaireLanding} />
              <Route path='/signin' component={LazySignIn} />
              <Route path='/vender-coche-landing' component={LazyUserLanding} />
              <Route path='/valorar-vehiculo' component={LazyAppraiseLanding} />
              <Route path='/politica-privacidad' component={LazyPrivacyPolicy} />
              <Route path='/politica-cookies' component={LazyCookiesPolicy} />
              <Route path='/aviso-legal' component={LazyLegalAdvice} />
              <Route path='/' exact component={Home} />
              <Redirect to='/' />
            </Switch>
          </Suspense>
        </Layout>
      );
    }
    return layout;
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.token !== null,
    autoAuthDone: state.auth.autoAuthDone
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignin: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
