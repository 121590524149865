import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Sidebar from '../../components/Layout/Sidebar/Sidebar';
import Header from '../../components/Layout/Header/Header';

import styles from './Layout.module.sass';
import 'react-toastify/dist/ReactToastify.min.css';

class Layout extends Component {
  render(){
    const noLayoutLocations = [
      '',
      'vender-coche-landing',
      'profesionales',
      'glosario',
      'vender-coche',
      'tasar-coche',
      'compramos-tu-coche'
    ];
    return(
      <>
        <ToastContainer />
        {
          this.props.isAuthenticated && noLayoutLocations.indexOf(this.props.location.pathname.split('/')[1]) === -1 ?
            <div className={styles.Layout}>
              <div className={styles.Menu}>
                <Sidebar pathname={this.props.location.pathname} />
              </div>
              <div className={styles.Content}>
                <div className={styles.Header}>
                  <Header user_name={this.props.user_name} />
                </div>
                <div className={styles.Content}>{this.props.children}</div>
              </div>
            </div>
            :
            this.props.children
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token != null,
    user_name: state.auth.name
  }
}

export default connect(mapStateToProps)(Layout);
