import { io } from 'socket.io-client';
import axios from './axios-checkauto';

export const socket = io(process.env.REACT_APP_API_URL);

export const updateObject = (oldObject, updatedProperties) => {
    return {
      ...oldObject,
      ...updatedProperties
    }
}

export const isBlank = (string) => {
  return string ? String(string).trim().length === 0 : true;
}

export const isNumbers = (string) => {
  return /^[0-9]+$/.test(string);
}

export const isPhone = (string) => {
  return /\+?[0-9]{9,}$/.test(string);
}

export const isEmail = (string) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(string);
}

export const isZip = (string) => {
  return /[0-9]{5}$/.test(string);
}

export const isSlug = (string) => {
  return /^[a-z0-9]+$/.test(string);
}

export const isNif = (string) => {
  return /[a-zA-Z]?[0-9]{7}[0-9]?[a-zA-Z]?$/.test(string);
}

export const randomString = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export const firstPhotoURL = (id, photos, conce_car = false) => {
  return carImageUrl(id, photos[0], conce_car);
}

export const carImageUrl = (id, photoName, conce_car = false) => {
  let url = `${process.env.REACT_APP_CDN_URL}/cars/${id}/${photoName}`;
  if(conce_car){
    url = `${process.env.REACT_APP_CDN_URL}/concecars/${id}/${photoName}`;
  }
  return url;
}

export const numberBeautifier = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const generateYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i > currentYear-50; i--) {
    years.push({
      value: i,
      label: i
    });
  }
  return years;
}

export const generateKms = () => {
  const kmsOptions = [];
  for (let i = 10000; i <= 500000; i += 10000) {
    kmsOptions.push({
      value: i,
      label: `menos de ${numberBeautifier(i)} Kms`
    });
  }
  return kmsOptions;
}

export const getBrands = async () => {
  try{
    const res = await axios.get('/db/getBrands');
    const brands = [];
    res.data.forEach((brand, i) => {
      brands.push({
        value: brand._id,
        label: brand.name
      });
    });
    return brands;
  }catch(err){
    console.log(err);
    return null;
  }
}

export const getModels = async (brandId) => {
  try{
    const res = await axios.get(`/db/getModels/${brandId}`);
    const models = [];
    res.data.forEach((model, i) => {
      models.push({
        value: model._id,
        label: model.name
      });
    });
    return models;
  }catch(err){
    console.log(err);
    return null;
  }
}

export const getYears = async (modelId) => {
  try{
    const res = await axios.get(`/db/getYears/${modelId}`);
    const years = [];
    res.data.forEach((year, i) => {
      years.push({
        value: year,
        label: year
      });
    });
    return years;
  }catch(err){
    console.log(err);
    return null;
  }
}

export const getFuels = async (modelId, year) => {
  const res = await axios.get(`/db/getFuels/${modelId}/${year}`);
  const fuels = [];
  res.data.forEach((fuel, i) => {
    fuels.push({
      value: fuel,
      label: fuel
    });
  });
  return fuels;
}

export const getPowers = async (modelId, year, fuel) => {
  const res = await axios.get(`/db/getPowers/${modelId}/${year}/${fuel}`);
  const powers = [];
  res.data.forEach((power, i) => {
    powers.push({
      value: power,
      label: `${power} cv`
    });
  });
  return powers;
}

export const getVerions = async (modelId, year, fuel, power) => {
  try{
    const res = await axios.get(`/db/getVersions/${modelId}/${year}/${fuel}/${power}`);
    const versions = [];
    res.data.forEach((version, i) => {
      versions.push({
        value: version._id,
        label: `${version.name} - ${version.generation.name}`
      });
    });
    return versions;
  }catch(err){
    console.log(err);
    return null;
  }
}

export const ownersToString = (owners) => {
  let res = '';
  switch (owners) {
    case 1:
      res = 'Único propietario';
      break;
    case 2:
      res = '2 propietarios';
      break;
    case 3:
      res = '3 propietarios';
      break;
    case 4:
      res = '4 propietarios';
      break;
    default:
      res = '';
  }
  return res;
}

export const parkingToString = (parking) => {
  let res = '';
  switch (parking) {
    case 1:
      res = 'En la calle';
      break;
    case 2:
      res = 'Garage comunitario';
      break;
    case 3:
      res = 'Garage privado';
      break;
    default:
      res = '';
  }
  return res;
}

export const fuelToString = (fuel) => {
  let res = '';
  switch (fuel) {
    case 1:
      res = 'Diésel';
      break;
    case 2:
      res = 'Gasolina';
      break;
    case 3:
      res = 'Eléctrico';
      break;
    case 4:
      res = 'Híbrido';
      break;
    case 5:
      res = 'Híbrido enchufable';
      break;
    case 6:
      res = 'GLP';
      break;
    case 7:
      res = 'Hidrógeno';
      break;
    case 8:
      res = 'Gas Natural';
      break;
    case 9:
      res = 'Otros';
      break;
    default:
      res = '';
  }
  return res;
}

export const gearshiftToString = (parking) => {
  let res = '';
  switch (parking) {
    case 1:
      res = 'Manual';
      break;
    case 2:
      res = 'Automático';
      break;
    default:
      res = '';
  }
  return res;
}

export const statusToString = (status, last_bid, bid_start_date) => {
  let remainingTime = null;
  if(bid_start_date){
    const elapseTime = (new Date() - new Date(bid_start_date))/1000;
    remainingTime = Number((process.env.REACT_APP_AUCTION_TIME*60) - elapseTime);
  }
  let res = '';
  switch (status) {
    case 'pending_fields':
      res = 'Pendiente de campos';
      break;
    case 'pending_photos':
      res = 'Pendiente de fotos';
      break;
    case 'pending_review':
      res = 'Pendiente de aprobar';
      break;
    case 'pending_auction':
      res = 'Pendiente de subasta';
      break;
    case 'at_auction':
      if(remainingTime){
        res = `En subasta (quedan ${Math.round(remainingTime/3600)} horas)`;
      }else{
        // res = `Puja finalizada (Sin pujas)`;
        res = `Puja finalizada`;
      }
      break;
    case 'finished':
      if(last_bid){
        res = `Puja finalizada (${numberBeautifier(last_bid.quantity)}€)`;
      }else{
        res = `Puja finalizada`;
      }
      break;
    case 'accepted':
      res = 'Puja aceptada';
      break;
    case 'rejected':
      res = 'Puja rechazada';
      break;
    case 'rejected_by_admin':
      res = 'Rechazado por admin';
      break;
    case 'closed':
      res = 'Cerrado';
      break;
    default:
      res = 'Indeterminado';
  }
  return res;
}

export const dateToString = (date) => {
  return new Date(date).toLocaleString();
}

export const sendGTMEvent = (params) => {
  if(process.env.NODE_ENV !== 'development'){
    window.dataLayer.push(params);
  }
}

export const initialPrice = (price) => {
  let minPrice = price * 0.85;
  if(price > 5000){
      minPrice = price * 0.86;
  }
  if(price > 5500){
      minPrice = price * 0.87;
  }
  if(price > 6000){
      minPrice = price * 0.88;
  }
  if(price > 6500){
      minPrice = price * 0.89;
  }
  if(price > 7000){
      minPrice = price * 0.90;
  }
  if(price > 8500){
      minPrice = price * 0.91;
  }
  if(price > 10500){
      minPrice = price * 0.92;
  }
  if(price > 13500){
      minPrice = price * 0.93;
  }
  if(price > 17000){
      minPrice = price * 0.94;
  }
  if(price > 20000){
      minPrice = price * 0.95;
  }
  return Math.floor(Math.trunc(minPrice)/50)*50;
}
