import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  token: null,
  name: null,
  rol: null,
  concessionaire_id: null,
  error: null,
  loading: false,
  autoAuthDone: false
}

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
}

const authReset = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    name: action.name,
    role: action.role,
    concessionaire_id: action.concessionaire_id,
    error: null,
    loading: false
  });
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
}

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    name: null,
  });
}

const autoAuthDone = (state, action) => {
  return updateObject(state, {
    autoAuthDone: true
  });
}

const nameChange = (state, action) => {
  return updateObject(state, {
    name: action.name
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_RESET: return authReset(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTO_AUTH_DONE: return autoAuthDone(state, action);
    case actionTypes.NAME_CHANGE: return nameChange(state, action);
    default: return state;
  }
}

export default reducer;
